import { PitchTrainerModel } from "@common/domain/models/PitchTrainer";
import { pitchTrainerActions } from "./actions";

export interface IPitchTrainerState {
    createPitch: {};
    pitchById: {};
    getPitch: [];
    updatePitch: {};
    deletePitch: {};
    loaders: {
        createPitch: boolean;
        getPitch: boolean;
        updatePitch: boolean;
        deletePitch: boolean;
    };
    errors: {
        createPitch: string;
        getPitch: string;
        updatePitch: string;
        deletePitch: string;
    };
}

export const initialState: IPitchTrainerState = {
    createPitch: {} as PitchTrainerModel,
    pitchById: {},
    getPitch: [],
    updatePitch: {} as PitchTrainerModel,
    deletePitch: {} as PitchTrainerModel,
    loaders: {
        createPitch: false,
        getPitch: false,
        updatePitch: false,
        deletePitch: false
    },
    errors: {
        createPitch: "",
        getPitch: "",
        updatePitch: "",
        deletePitch: ""
    },
};

export const pitchTrainerReducer = (
    state: IPitchTrainerState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {

        case pitchTrainerActions.CREATE_PITCH:
            return {
                ...state,
                loaders: { ...state.loaders, createPitch: true },
                errors: { ...state.errors, createPitch: "" }
            };

        case pitchTrainerActions.CREATE_PITCH_SUCCESS:
            return {
                ...state,
                createPitch: action.payload,
                loaders: { ...state.loaders, createPitch: false }
            };
        case pitchTrainerActions.CREATE_PITCH_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, createPitch: false },
                errors: { ...state.errors, createPitch: action.payload }
            };
        case pitchTrainerActions.GET_PITCH:
            return {
                ...state,
                loaders: { ...state.loaders, getPitch: true },
                errors: { ...state.errors, getPitch: "" }
            };

        case pitchTrainerActions.GET_PITCH_SUCCESS:
            updatePitchMap(action.payload, state.pitchById);
            return {
                ...state,
                getPitch: action.payload,
                loaders: { ...state.loaders, getPitch: false }
            };
        case pitchTrainerActions.GET_PITCH_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getPitch: false },
                errors: { ...state.errors, getPitch: action.payload }
            };
        case pitchTrainerActions.UPDATE_PITCH:
            return {
                ...state,
                loaders: { ...state.loaders, updatePitch: true },
                errors: { ...state.errors, updatePitch: "" }
            };

        case pitchTrainerActions.UPDATE_PITCH_SUCCESS:
            updatePitchMap([action.payload], state.pitchById);
            return {
                ...state,
                updatePitch: action.payload,
                loaders: { ...state.loaders, updatePitch: false }
            };
        case pitchTrainerActions.UPDATE_PITCH_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, updatePitch: false },
                errors: { ...state.errors, updatePitch: action.payload }
            };        
        case pitchTrainerActions.DELETE_PITCH:
            return {
                ...state,
                loaders: { ...state.loaders, deletePitch: true },
                errors: { ...state.errors, deletePitch: "" }
            };

        case pitchTrainerActions.DELETE_PITCH_SUCCESS:
            deletePitchMap([action.payload], state.pitchById);
            return {
                ...state,
                deletePitch: action.payload,
                loaders: { ...state.loaders, deletePitch: false }
            };
        case pitchTrainerActions.DELETE_PITCH_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, deletePitch: false },
                errors: { ...state.errors, deletePitch: action.payload }
            };            
        default:
            return state;
    }
};

export const updatePitchMap = (pitches, existingMap) => {
    pitches.forEach((pitch) => {
      existingMap[pitch.id] = pitch
    });
  };

export const deletePitchMap = (pitches, existingMap) => {
    delete existingMap[pitches.id];
};

