import { APP_MENU_ITEM } from '@constants/config';
import { Routes } from '@navigator/Routes';
import { IAppMenuItem } from './Dashboard';

const getMenuByPathName = (pathName: string, menuItems: IAppMenuItem[]) => {
    const entities = pathName.split('/');
    if (entities.length >= 2) {
        const pageName = entities[2];

        let key = '';

        if (Routes.Home.home.includes(pageName)) {
            key = APP_MENU_ITEM.DASHBOARD;
        } else if (pageName.toLowerCase().includes(APP_MENU_ITEM.INTERVIEW)) {
            key = APP_MENU_ITEM.INTERVIEW;
        } else if (pageName.toLowerCase().includes(APP_MENU_ITEM.PITCH_TRAINER)) {
            key = APP_MENU_ITEM.PITCH_TRAINER;
        } else {
            menuItems.forEach((menuItem) => {
                if (menuItem?.url?.toLowerCase()?.includes(pageName.toLowerCase())) {
                    key = menuItem.key;
                }
            });
        }
        return key;
    }
    return '';
};

export const LayoutUtil = {
    getMenuByPathName,
};
