import { all } from 'redux-saga/effects';

import { watchAuth } from '@common/redux/auth/sagas';
import { watchComments } from '@common/redux/comments/sagas';
import { watchCommons } from '@common/redux/common/sagas';
import { watchConnection } from '@common/redux/connections/sagas';
import { watchAICoach } from '@common/redux/course/AICoach/sagas';
import { watchCourses } from '@common/redux/course/sagas';
import { watchMeeting } from '@common/redux/meeting/sagas';
import { watchNotifications } from '@common/redux/notifications/sagas';
import { watchProfiles } from '@common/redux/profile/sagas';
import { watchUploadFiles } from '@common/redux/upload/sagas';
import { watchZoomSaga } from '@common/redux/zoom/sagas';

import { watchBatches } from "@redux/batch/sagas";
import { watchChatActions } from "@redux/chat/sagas";
import { watchFacultyReport } from "@redux/facultyReport/sagas";
import { watchInstitutes } from "@redux/institutes/sagas";
import { watchLocations } from "@redux/location/sagas";
import { watchRoot } from "@redux/root/sagas";
import { watchSettings } from "@redux/settings/sagas";
import { watchSkillsScores } from "@redux/skillsScore/sagas";
import { watchStudentReport } from "@redux/studentReport/sagas";
import { watchContent } from "@common/redux/content/saga";
import { watchPrepare } from '@common/redux/interview/sagas';
import { watchEvent } from '@common/redux/event/sagas';
import { watchMentor } from '@common/redux/mentor/saga';

import { watchInstituteAdmin } from '@common/redux/instituteAdmin/sagas';
import { watchForumSaga } from '@common/redux/forum/saga';
import { watchProgram } from '@common/redux/program/sagas';
import { watchPitchTrainer } from '@common/redux/pitchTrainer/sagas';

export default function* rootSaga(): Generator {
  yield all([
    watchBatches(),
    watchLocations(),
    watchProfiles(),
    watchCourses(),
    watchInstitutes(),
    watchUploadFiles(),
    watchAuth(),
    watchChatActions(),
    watchConnection(),
    watchMeeting(),
    watchCommons(),
    watchRoot(),
    watchStudentReport(),
    watchFacultyReport(),
    watchSkillsScores(),
    watchComments(),
    watchNotifications(),
    watchSettings(),
    watchZoomSaga(),
    watchAICoach(),
    watchContent(),
    watchPrepare(),
    watchEvent(),
    watchInstituteAdmin(),
    watchForumSaga(),
    watchProgram(),
    watchMentor(),
    watchPitchTrainer()
  ]);
}

