export const layoutMessages = {

    dashboardMenu: {
        key: 'layout.labelsAndTitles.DASHBOARD_MENU'
    },

    cohortsMenu: {
        key: 'layout.labelsAndTitles.COHORTS_MENU'
    },

    venturesMenu: {
        key: 'layout.labelsAndTitles.VENTURES_MENU'
    },

    myProfileTitle: {
        key: 'layout.labelsAndTitles.MY_PROFILE_TITLE'
    },

  logoutTitle: {
    key: 'layout.labelsAndTitles.LOGOUT_TITLE'
  },
  calendarMenu: {
    key: 'layout.labelsAndTitles.CALENDAR'
  },
    studentMenu: {
    key: 'layout.labelsAndTitles.STUDENTS_MENU'
  },
  coursesMenu: {
    key: 'layout.labelsAndTitles.COURSES_MENU'
  }, 
  libraryMenu: {
    key: 'layout.labelsAndTitles.LIBRARY'
  },
  reportsMenu: {
    key: 'layout.labelsAndTitles.PERFORMANCE'
  },
  allBatches: {
    key: 'regionalManager.labelsAndTitles.ALL_COHORTS'
  },
  help: {
    key: 'layout.labelsAndTitles.HELP'
  },
  settingsTitle: {
    key: "layout.labelsAndTitles.SETTINGS",
  },
  interviewMenu: {
     key: 'layout.labelsAndTitles.INTERVIEW_MENU'
  },
  forumsMenu: {
    key: 'layout.labelsAndTitles.FORUMS_MENU'
  },
  careersMenu: {
    key: 'layout.labelsAndTitles.CAREERS_MENU'
  },
  myForums: {
    key: 'layout.labelsAndTitles.MY_FORUMS'
  },
  recommendedForums: {
    key: 'layout.labelsAndTitles.RECOMMENDED_FORUMS'
  },
  forumModeration: {
    key: 'layout.labelsAndTitles.FORUM_MODERATION'
  },
  mentorsMenu: {
    key: 'layout.labelsAndTitles.MENTORS'
  },
  BannerTitle: {
    key: 'layout.labelsAndTitles.BANNER_TITLE'
  },
  BannerSubTitle: {
    key: 'layout.labelsAndTitles.BANNER_SUB_TITLE'
  },
  pitchTrainerMenu: {
    key: 'layout.labelsAndTitles.PITCH_TRAINER'
  },
  interviewCoachMenu: {
    key: 'layout.labelsAndTitles.INTERVIEW_COACH_MENU'
  }
};


